// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$website-primary: mat.m2-define-palette((
  50: rgb(255, 242, 231),
  100: rgb(255, 228, 178),
  200: rgb(255, 216, 143),
  300: rgb(255, 209, 179),
  400: rgb(255, 194, 153),
  500: rgb(255, 178, 126),
  600: rgb(255, 177, 132),
  700: rgb(255, 172, 116),
  800: rgb(255, 134, 66),
  900: rgb(222, 116, 56),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: white,
  )
));
$website-accent: mat.m2-define-palette((
  50: rgb(241 250 254),
  100: rgb(213 240 253),
  200: rgb(184 230 251),
  300: rgb(169 225 250),
  400: rgb(138 215 248),
  500: rgb(128, 182, 245),
  600: rgb(88 177 213),
  700: rgb(74 151 182),
  800: rgb(47 101 123),
  900: rgb(23 55 68),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white,
  )
));
// The warn palette is optional (defaults to red).
$website-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Typography
$regular-font-family: "'Poppins', sans-serif";
$website-typography: mat.m2-define-typography-config(
  $font-family: $regular-font-family,
  $headline-1: mat.m2-define-typography-level($font-size: 43px, $font-weight: 700, $line-height: 57px),
  $headline-2: mat.m2-define-typography-level($font-size: 40px, $font-weight: 700, $line-height: 54.5px),
  $headline-3: mat.m2-define-typography-level($font-size: 40px, $font-weight: 700, $line-height: 54.5px),
  $headline-4: mat.m2-define-typography-level($font-size: 33px, $font-weight: 700, $line-height: 42.5px),
  $headline-5: mat.m2-define-typography-level($font-size: 28px, $font-weight: 700, $line-height: 45px),
  $headline-6: mat.m2-define-typography-level($font-size: 25px, $font-weight: 700, $line-height: 42px),
  $subtitle-1: mat.m2-define-typography-level($font-size: 26px, $font-weight: 600, $line-height: 43px),
  $subtitle-2: mat.m2-define-typography-level($font-size: 20px, $line-height: 30px),
  $body-1: mat.m2-define-typography-level($font-size: 18px, $font-weight: 500, $line-height: 28px),
  $body-2: mat.m2-define-typography-level($font-size: 17px, $font-weight: 500, $line-height: 25px),
  $button: mat.m2-define-typography-level($font-size: 18px, $font-weight: 600, $line-height: 38px),
);
$website-typography-md: mat.m2-define-typography-config(
  $font-family: $regular-font-family,
  $headline-1: mat.m2-define-typography-level($font-size: 36px, $font-weight: 700, $line-height: 49px),
  $headline-2: mat.m2-define-typography-level($font-size: 35px, $font-weight: 700, $line-height: 47.5px),
  $headline-3: mat.m2-define-typography-level($font-size: 33px, $font-weight: 700, $line-height: 44.5px),
  $headline-4: mat.m2-define-typography-level($font-size: 28px, $font-weight: 700, $line-height: 32.5px),
  $headline-5: mat.m2-define-typography-level($font-size: 26px, $font-weight: 700, $line-height: 28px),
  $headline-6: mat.m2-define-typography-level($font-size: 20px, $font-weight: 700, $line-height: 22px),
  $subtitle-1: mat.m2-define-typography-level($font-size: 24px, $font-weight: 600, $line-height: 28px),
  $subtitle-2: mat.m2-define-typography-level($font-size: 18px, $line-height: 23px),
  $body-1: mat.m2-define-typography-level($font-size: 18px, $font-weight: 500, $line-height: 25px),
  $body-2: mat.m2-define-typography-level($font-size: 16px, $font-weight: 500, $line-height: 20px),
  $button: mat.m2-define-typography-level($font-size: 18px, $font-weight: 600, $line-height: 25px),
);
$website-typography-xs: mat.m2-define-typography-config(
  $font-family: $regular-font-family,
  $headline-1: mat.m2-define-typography-level($font-size: 34px, $font-weight: 700, $line-height: 40px),
  $headline-2: mat.m2-define-typography-level($font-size: 30px, $font-weight: 700, $line-height: 47.5px),
  $headline-3: mat.m2-define-typography-level($font-size: 28px, $font-weight: 700, $line-height: 44.5px),
  $headline-4: mat.m2-define-typography-level($font-size: 25px, $font-weight: 700, $line-height: 32.5px),
  $headline-5: mat.m2-define-typography-level($font-size: 22px, $font-weight: 700, $line-height: 28px),
  $headline-6: mat.m2-define-typography-level($font-size: 20px, $font-weight: 700, $line-height: 22px),
  $subtitle-1: mat.m2-define-typography-level($font-size: 26px, $font-weight: 600, $line-height: 28px),
  $subtitle-2: mat.m2-define-typography-level($font-size: 20px, $line-height: 23px),
  $body-1: mat.m2-define-typography-level($font-size: 18px, $font-weight: 500, $line-height: 25px),
  $body-2: mat.m2-define-typography-level($font-size: 16px, $font-weight: 500, $line-height: 20px),
  $button: mat.m2-define-typography-level($font-size: 18px, $font-weight: 600, $line-height: 25px),
);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$website-theme: mat.m2-define-light-theme((
  typography: $website-typography,
  color: (
    primary: $website-primary,
    accent: $website-accent,
    warn: $website-warn,
  )
));


@mixin loadClass($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $tertiary-color: #00B3FF;
  
  .primary-bg-light {
    background-color: mat.m2-get-color-from-palette($primary-palette, 50);
  }
  .primary-bg-normal {
    background-color: mat.m2-get-color-from-palette($primary-palette, 200);
  }
  .primary-bg-dark {
    background-color: mat.m2-get-color-from-palette($primary-palette, 600);
  }
  .primary-bg-darker {
    background-color: mat.m2-get-color-from-palette($primary-palette, 800);
  }
  .primary-border-normal {
    border-color: mat.m2-get-color-from-palette($primary-palette, 200) !important;
  }
  .primary-border-dark {
    border-color: mat.m2-get-color-from-palette($primary-palette, 600) !important;
  }
  .primary-border-darker {
    border-color: mat.m2-get-color-from-palette($primary-palette, 800) !important;
  }
  .primary-color-light {
    color: mat.m2-get-color-from-palette($primary-palette, 50);
  }
  .accent-bg {
    background-color: mat.m2-get-color-from-palette($accent-palette, 500);
  }
  .tertiary-border {
    border-color: $tertiary-color !important;
  }

  // Material Components
  .mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
    border-color: mat.m2-get-color-from-palette($accent-palette, 500) !important;
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.typography-hierarchy($website-typography);
@include mat.all-component-themes($website-theme);
@include loadClass($website-theme);

// #MD
@media screen and (min-width: 62em) and (max-width: 75em) {
  @include mat.typography-hierarchy($website-typography-md);
}
// #SM
@media screen and (min-width: 48em) and (max-width: 62em) {
  @include mat.typography-hierarchy($website-typography-md);
}
// #XS
@media screen and (max-width: 48em) {
  @include mat.typography-hierarchy($website-typography-xs);
}

// Margin & Padding
// margin and padding values array
$space-values : (
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    16: 64,
) !default;

// margin and padding shorthands
$space-prefixes : (
  p  : padding,
  pt : padding-top,
  pr : padding-right,
  pb : padding-bottom,
  pl : padding-left,
  m  : margin,
  mt : margin-top,
  mr : margin-right,
  mb : margin-bottom,
  ml : margin-left,
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes { 
    @each $key, $value in $space-values {
      .#{$attr-short}-#{$key} {
        #{$attr-long}: #{$value}#{'px'};
      }
    }
  }
}

@include make-spaces();

/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: "Poppins", sans-serif;  @extend .primary-bg-light; }
img { margin: 0 auto; max-width: 100% }
p { text-align: start; }

// Helpers
.text-center  { text-align: center; }
.flex-center  { display: flex; justify-content: center; }
.flex-end     { display: flex; justify-content: flex-end; }

// Font
.f-w-500 { font-weight: 500 !important; }
.f-w-600 { font-weight: 600 !important; }

// Angular Material
// Typography
.mat-typography {
  .mat-headline-1,
  .mat-headline-2,
  .mat-headline-3,
  .mat-headline-4,
  .mat-headline-5,
  .mat-headline-6,
  .mat-subtitle-1,
  .mat-subtitle-2,
  .mat-body-1,
  .mat-body-2,
  p {
    margin: 0;
  }  
}

// MatTab
.hide-tabs { 
  > .mat-mdc-tab-header {
    display: none;
  }
}

// MatButton
.mdc-button.mat-mdc-button-base.basic {
  border-radius: 10px;
  padding: 0 18px;
  height: 45px;
  &.light {
    @extend .primary-bg-light;
  }
  &.thick {
    height: 54px;
  }
  &.no-shadow {
    box-shadow: none;
  }
}
.mat-mdc-raised-button {
  padding: 20px 16px !important;
  --mdc-protected-button-container-shape: 10px;
}
.mat-mdc-outlined-button {
  padding: 18.5px 16px !important;
  --mdc-outlined-button-outline-width: 3px;
  --mdc-outlined-button-label-text-color: #000 !important;
  --mdc-outlined-button-outline-color: #FFE9DB;
  --mdc-outlined-button-container-shape: 10px;
}
.header-menu-icon-button.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 3px !important;
}

// MatTabs
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #000 !important;
  --mat-tab-header-active-focus-label-text-color: #000 !important;
  --mat-tab-header-active-hover-label-text-color: #000 !important;
  --mat-tab-header-active-focus-indicator-color: #000 !important;
  --mat-tab-header-active-hover-indicator-color: #000 !important;
  --mat-tab-header-active-label-text-color: #000 !important;
  --mat-tab-header-active-ripple-color: #000 !important;
  --mat-tab-header-inactive-ripple-color: #000 !important;
}
.mat-mdc-tab-header {
  --mdc-tab-indicator-active-indicator-height: 6px !important;
}
.mat-mdc-tab {
  --mdc-secondary-navigation-tab-container-height: 60px;
}

// MatExpansionPanel 
.mat-expansion-panel {
  --mat-expansion-container-background-color: transparent;
  --mat-expansion-container-shape: 0px !important;
  --mat-expansion-header-collapsed-state-height: 62px;
  box-shadow: none !important;
  border-top: 1px #5E5E5E80 solid;
  border-radius: 0;
}
.mat-expansion-panel-header-description {
  justify-content: end;
  flex-grow: 0 !important;
  margin-right: 0 !important;
}
.mat-expansion-panel-spacing {
  margin: 0 !important;
}

// MatInput
.sub-newsletter {
  .mdc-text-field--filled.mdc-text-field--disabled {
    cursor: pointer !important;
    background-color: transparent !important;
    padding: 0;
  }
}

// MatFormField
.contact-field {
  width: 100%;
  .mat-mdc-text-field-wrapper {
    @extend .primary-bg-light;
  }
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 0;
    --mdc-outlined-text-field-focus-outline-width: 0;
    border-radius: 15px;
  }
  &.select, &.input {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
      height: 40px;
      min-height: auto;
    }
  }
}

// MatSelect
.contact-field-select {
  margin: 0 5%;
  padding: 0 !important;
}

// MatIcon
.icon-chevron {
  width: 30px !important;
  height: 30px !important;
  font-size: 30px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 10px;
  &.primary-light {
    @extend .primary-bg-light;
  }
  &.accent {
    @extend .accent-bg;
  }
}

// MatMenu
.mat-mdc-menu-content {
  padding: 0 !important;
}
.mat-menu-custom {
  box-shadow: none !important;
  padding: 15px !important;
  // border-radius: 0 0 20px 20px !important;
  border-radius: 20px !important;
  // margin-top: 18px;
  &.large {
    max-width: 360px !important;
  }
  .mat-mdc-menu-item:not([disabled]):hover {
    border-radius: 10px;
    @extend .primary-bg-light;
  }
}

// Custom Elements
.custom-card {
  padding: 30px;
  border-radius: 60px;
}
.custom-title {
  margin-bottom: 20px;
  span {
    @extend .mat-headline-5;
    @extend .primary-color-light;
    padding: 10px 20px;
    border-radius: 10px;
    text-transform: uppercase;
  }
}
.custom-numbers {
  text-align: center;
  span {
    display: block;
    font-weight: 600;
  }
  p {
    font-size: 25px;
  }
  .icon {
    height: 70px;
    img {
      width: auto;
    }
  }
}
.custom-info-hover {
  position: relative;
  border: solid;
  height: calc(100% - 8px);
  border-width: 4px;
  // width: 280px;
  border-radius: 42px;
  overflow: hidden;
  @extend .primary-border-darker;
  &.by-4 {
    .img {
      background-size: 140%;
      height: 250px;
    }
    .hover {
      height: 250px;
    }
  }
  .label {
    padding: 15px 25px;
    margin: 0;
  }
  .img {
    width: 100%;
    height: 280px;
    background-size: 110%;
    background-position: 50%;
    border-radius: 40px;
  }
  .hover {
    position: absolute;
    display: none;
    height: 280px;
    border-radius: 40px;
    // background-color: rgba(255, 126, 40, 0.4);
    @extend .primary-bg-dark;

    
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .heading {
      text-align: center;
      margin-top: 20px;
      span {
        @extend .primary-bg-darker;
        @extend .mat-body-1;
        padding: 5px 10px;
        border-radius: 10px;
        color: #FFE9DB;
        cursor: default;
      }
    }
    .text {
      text-align: center;
      @extend .mat-body-2;
      line-height: 25px !important;
      padding: 10px 15px;
      margin-top: 15px;
      cursor: default;
    }
  }
  &:hover {
    .hover {
      // display: block;
      display: flex;
    }
  }
}

// App
.app-main-actions {
  display: flex;
  justify-content: center;
}
.end-img-postfooter {
  max-width: 300px;
}

// Offer
.offer-partner {
  img {
    padding: 8px 8px;
    max-width: 100px;
    vertical-align: middle;
  }
}
.offer-number {
  border-radius: 30px;
  padding: 0 25px;
  text-align: center;
  height: 100%;
  .item {
    padding: 20px 0;
    .label {
      margin-top: 10px;
    }
  }
}
.offer-tuto-item {
  background: #FFF5E6;
  border-radius: 20px;
  padding: 25px;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: #000;
}

.newsletter-modal {
  width: 360px;
  height: 580px;
  iframe {
    height: 100%;
  }
}


// #XS Responsive
@media screen and (max-width: 48em) {
  // MatButton
  .mdc-button {
    --mdc-typography-button-font-size: 18px;
  }
  // MatExtensionPanel
  .mat-expansion-panel {
    --mat-expansion-header-collapsed-state-height: 95px;
  }
  // Delete Padding
  .c-xs-np {
    padding-top: 0;
    padding-bottom: 0;
  }
  // Add Padding
  .cs-xs-p {
    padding: 0 1rem 0 1rem;
  }
  // Alignement
  .c-xs-tc {
    text-align: center;
  }
  // Row Reverse
  .row {
    &.c-xs-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  // Resize Img
  .cs-xs-img {
    max-width: 250px;
  }
  // Show / Hide 
  .c-xs-hide {
    display: none !important;
  }
  // Margins
  .c-xs-mt-min {
    margin-top: 15px;
  }
  .c-xs-mb-min {
    margin-bottom: 15px;
  }
  .c-xs-mt-med {
    margin-top: 30px;
  }
  .c-xs-mb-med {
    margin-bottom: 30px;
  }

  // Custom Elements
  .custom-card {
    padding: 10px;
  }
  .offer-partner {
    text-align: center;
    img {
      max-width: 95px;
    }
  }
  .custom-info-hover {
    height: auto;
    width: 270px;
    margin: 0 auto;
    .img {
      height: 260px;
    }
    .hover {
      height: 262px;
    }
  }
}